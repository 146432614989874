/* You can add global styles to this file, and also import other style files */
@import "assets/variables";
@import "~@progress/kendo-theme-default/dist/all.scss";
@import "material-icons/iconfont/material-icons.css";

.global-footer {
  background-color: #37444c;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto;
  }
}

.pl-1 {
  padding-left: 5px;
}

.pl-2 {
  padding-left: 10px;
}

.pl-3 {
  padding-left: 15px;
}

.pl-4 {
  padding-left: 20px;
}

.pr-1 {
  padding-right: 5px;
}

.pr-2 {
  padding-right: 10px;
}

.pr-3 {
  padding-right: 15px;
}

.pr-4 {
  padding-right: 20px;
}

.pb-1 {
  padding-bottom: 5px;
}

.pb-2 {
  padding-bottom: 10px;
}

.pb-3 {
  padding-bottom: 15px;
}

.mt--1 {
  margin-top: -5px;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.ml-3 {
  margin-left: 1.5rem !important;
}

.mr-1 {
  margin-left: 0.5rem !important;
}

.mr-2 {
  margin-left: 1rem !important;
}

.mr-3 {
  margin-left: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 1.6rem !important;
}

.pl-0 {
  margin-left: 0 !important;
}

.recover-error-message {
  margin-left: 0px;
  color: $footer-white;
  background-color: rgba($danger-color, 0.9) !important;
  border-radius: 5px;
  padding: 12px 20px 12px 20px;
  font-size: $font-s-em;
  margin-bottom: 20px;
  word-wrap: break-word;
}

.btn-primary {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  min-width: 240px;
  padding: 10px;

  font-style: normal;
  font-weight: bold;
  font-size: $font-xs;
  /* or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.5) !important;
  }

  &:active {
    background-color: unset !important;
    color: $primary-color !important;
  }
}

.primary-color {
  color: $primary-color !important;
}

.button-hover {
  position: relative;
  display: inline-block;
  .tooltiptext {
    visibility: hidden;
    width: 240px;
    color: $danger-color;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    font-size: $font-xs !important;
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
}

.step-error {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .errorMessage {
    color: $danger-color;
    font-size: $font-xs !important;
    text-align: center;
  }
}

.btn-primary-outline {
  background-color: white !important;
  border-color: $primary-color !important;
  min-width: 240px;
  padding: 10px;

  font-style: normal;
  font-weight: bold;
  font-size: $font-xs;
  /* or 129% */
  color: $primary-color;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.5) !important;
    color: $primary-color;
  }

  &:active {
    background-color: unset !important;
    color: $primary-color !important;
  }
}

.color-secondary {
  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($secondary-color, 0.5) !important;
  }

  &:active {
    background-color: unset !important;
    color: $secondary-color !important;
  }
}

.btn-secondary {
  background-color: $secondary-color !important;
  border-color: $secondary-color !important;
  min-width: 240px;
  padding: 10px;

  font-style: normal;
  font-weight: bold;
  font-size: $font-xs;
  /* or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($secondary-color, 0.5) !important;
  }

  &:active {
    background-color: unset !important;
    color: $secondary-color !important;
  }
}

.icon {
  text-decoration: none !important;
  &:hover,
  &:visited {
    text-decoration: none !important;
  }
}

* {
  font-family: "Open Sans", sans-serif;
}

.font-sans {
  font-family: "Open Sans", sans-serif;
}

.main-content-container {
  //min-width: 600px;
  min-height: 60vh;
}

.h1-heading {
  font-style: normal;
  font-weight: bold;
  font-size: $font-xl;
  line-height: 40px;
}

.subheading {
  font-style: normal;
  font-weight: bold;
  font-size: $font-m;
  line-height: 18px;
}

.control-label {
  font-style: normal;
  font-weight: normal;
  font-size: $font-s;
  line-height: 24px;
  margin-top: 20px;
}

.mt-20px {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.form-group {
  label {
    line-height: 5px !important;
  }
}

.form-control.is-invalid {
  background-image: none !important;
}

.form-control {
  height: 50px;
  font-size: $font-s !important;
}

select.form-control {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}

.tile-color {
  color: $tile-color !important;
}

.grey-heading {
  color: $gray-color !important;
}

.secondary-color {
  color: $secondary-color;
}

.secondary-link-normal {
  color: $secondary-color;
  text-decoration: none;
  font-style: normal;
}

.secondary-link-normal:hover {
  cursor: pointer;
  color: $secondary-color;
  text-decoration: underline;
}

.no-list {
  list-style: none !important;
}

.secondary-link {
  color: $secondary-color;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: $font-s;
}
.secondary-link:hover {
  :not(.material-icons) {
    text-decoration: underline;
  }
  cursor: pointer;
  color: $secondary-color;
}
.grey-link {
  color: $gray-color;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: $font-s;
}

.grey-link:hover {
  cursor: pointer;
  color: $gray-color;
  text-decoration: underline;
}

.grey-message {
  font-style: normal;
  font-weight: normal;
  font-size: $font-s;
  line-height: 24px;
  color: $gray-color;
}

p {
  margin-bottom: $font-xs-em !important;
}

.form-group label {
  color: #525252;
  font-family: "Open Sans", sans-serif;
  font-size: $font-s;
  font-weight: 600;
  line-height: 23px;
}

.label-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.link-with-image {
  display: flex;
  width: max-content;
}

label.required {
  color: #f44236;
}

.success-block {
  color: #28a745;
}

input.ng-touched.ng-invalid {
  border-color: red;
}

.ng-valid.ng-touched {
  border-color: #28a745 !important;
}

.bad {
  color: red !important;
  display: block;
}

.hover-hand:hover {
  cursor: pointer;
}

.helptext {
  font-style: italic;
  font-weight: normal;
  font-size: $font-xs;
  line-height: 24px;
  color: $light-gray-color;
}

.full-height {
  height: max-content;
}

.bg-light {
  background-color: $light-background;
}

.steps-page-heading {
  font-style: normal;
  font-weight: bold;
  font-size: $font-l;
  line-height: 18px;
  color: $primary-color;

  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: $secondary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.download-file-error {
  font-size: $font-s;
}

.subheading-18p {
  font-style: normal;
  font-weight: bold;
  font-size: $font-m;
  line-height: 18px;
  color: $primary-color;

  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: $secondary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p:not(:last-child) {
    line-height: 30px !important;
  }
  p:first-child {
    line-height: 30px !important;
  }
}

.steps-page-heading-2 {
  font-style: normal;
  font-weight: bold;
  font-size: $font-xl;
  line-height: 25px;
  color: $tile-color;
}

.p-content {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: $font-s;
    line-height: 24px;
    color: $gray-color;
  }

  ul li {
    margin-left: 1.5rem;
    font-style: normal;
    font-weight: normal;
    font-size: $font-s;
    line-height: 24px;
    color: $gray-color;
  }

  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: $font-m;
    line-height: 28px;
  }

  a {
    color: $secondary-color;
    text-decoration: none;
    font-style: normal;
  }

  a:hover {
    cursor: pointer;
    color: $secondary-color;
    text-decoration: underline;
  }
}

.row.info-banner {
  background: #ffffff;
  border: 1px solid $tile-color;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  // min-height: 250px;
  min-height: max-content !important;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: $font-s;
    line-height: 24px;
    color: $gray-color;
  }

  ul li {
    margin-left: 1.5rem;
    font-style: normal;
    font-weight: normal;
    font-size: $font-s;
    line-height: 24px;
    color: $gray-color;
  }

  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: $font-m;
    line-height: 28px;
  }

  a {
    color: $secondary-color;
    text-decoration: none;
    font-style: normal;
  }

  a:hover {
    cursor: pointer;
    color: $secondary-color;
    text-decoration: underline;
  }
}

.heading-3 {
  font-style: normal;
  font-weight: bold;
  font-size: $font-l;
  line-height: 32px;
  color: $tile-color;
}

.overlay-fill {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 5;
  width: 100%;
  height: 100%;
  /* display: none; */
}

.button-center {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
}

.gray-div {
  width: 250px;
  height: 250px;
  background: $footer-white;
  border-radius: 10px;
}

.image-button {
  background: $footer-white;
  border: 1px solid $primary-color;
  box-sizing: border-box;
  border-radius: 5px;
  width: 160px;
  height: 160px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 10px;

  app-svg {
    display: flex;
    justify-content: center;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: $font-xs;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: -0.25px;
    color: $primary-color;
  }
}

.center-card {
  width: 450px;
  height: 700px;
}

.bottom-section {
  bottom: 10px;
}

.b-gray strong {
  color: $gray-color !important;
}
.b-green strong {
  color: $secondary-color !important;
}
.b-red strong {
  color: $danger-color !important;
}
.b-yellow strong {
  color: $warning-color !important;
}

.danger-text {
  color: $danger-color !important;
}

.distribute-evenly {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}
.steps-page-heading.gray {
  span {
    color: $gray-color !important;
  }
}

.steps-page-heading.green {
  span {
    color: $secondary-color !important;
  }
}

.steps-page-heading.red {
  span {
    color: $danger-color !important;
  }
}

.no-margin-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.h6-heading {
  font-style: normal;
  font-weight: bold;
  font-size: $font-xs;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  /* Kroll Dark Grey */
  color: $gray-color;
}

.w-40 {
  width: 40%;
}

.group-items-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cursor-notallowed {
  cursor: not-allowed !important;
}

textarea.form-control {
  height: auto !important;

  &.ng-touched.ng-invalid {
    border-color: red;
  }
}

.bg-transparent {
  background: transparent !important;
}

.warning-div {
  background: #fffce0;
  border: 1px solid #fdc006;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  margin-top: 23px;
}

.dynamic-html {
  h1 {
    font-size: $font-xl;
  }
  h2 {
    font-size: $font-l;
  }
  h3 {
    font-size: $font-m;
  }
  p,
  ul li {
    font-size: $font-s;
  }
  line-height: 23px;
}

.post-login-header {
  height: 100px;
  border-bottom: 3px solid $primary-color !important;

  .logo-img {
    max-height: 90px;
  }

  .client-logo-img {
    padding-top: 20px;
    padding-bottom: 20px;
    max-height: 90px;
  }

  .logo-seperator {
    border-right: 1px solid $primary-color;
    content: "";
    top: 50px;
    margin-top: 31px;
    margin-bottom: 29px;
    margin: 24px 24px 22px 22px;
  }

  .logos-div {
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
  }

  .main-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    /* color: #455560; */
  }

  a.nav-row {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 1.3rem;
    text-decoration: none;
    color: $gray-color;

    &:hover {
      color: $light-gray-color;
    }
  }

  .nav-row i {
    padding-bottom: 4px;
    text-decoration: none;
  }

  .nav-row span {
    align-items: center;
    letter-spacing: 0.5px;
    font-style: normal;
    font-weight: bold;
    font-size: $font-xs;
    line-height: 18px; /* color: #455560; */
    text-transform: uppercase;
  }
}

.table {
  //border: 1px solid #455560;
  box-sizing: border-box;

  .outside-delete-button {
    position: absolute;
    .close-svg-icon {
      position: relative;
      right: -50px;
      top: -45px;
    }
  }
}

.table .table-heading {
  background: #f2f2f2;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: $font-s;
  line-height: 28px;
  /* or 156% */
  display: flex;
  align-items: center;

  /* Kroll Blue - Primary */
  color: #14487f;
  height: 37px;
}

.table .table-content {
  min-height: 25px;
  display: flex;
  align-items: center;
  font-size: $font-s !important;

  .side-heading {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: $font-s;
    line-height: 28px;
    /* or 156% */

    display: flex;
    align-items: center;

    /* Kroll Dark Grey */

    color: #455560;
  }
}

.font-m {
  font-size: $font-m;
}

.k-progressbar .k-selected {
  background-color: $secondary-color !important;
}

kendo-progressbar {
  max-width: 300px;
}

kendo-grid {
  .k-button + .k-button {
    width: 70px !important;
    margin-inline-start: 0px !important;
  }

  .k-grid-header {
    background-color: $footer-white;
  }
  .k-column-title,
  th.k-header,
  th.k-header.k-filterable {
    text-transform: uppercase;
    font-size: $font-xs;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: $primary-color;
  }

  .k-textbox {
    width: 100%;
  }
  .kendo-grid-custom-link {
    display: flex;
    flex-direction: column;

    a {
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
//media query for mobile screen
@media only screen and (max-width: 600px) {
  .header {
    .logo-img {
      max-height: 70x;
    }

    .container-fluid.header {
      border-bottom: 2px solid $primary-color;
      height: 70px;
    }
  }
}
.search-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #f4f5f4;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.right-side-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.right-side-div button {
  margin-left: 10px;
}

.form-group.input-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  .icon {
    position: absolute;
    color: #718096;
    padding-right: 13px;
  }
}

.k-grid-toolbar {
  width: 100% !important;
}

td[role="gridcell"] {
  height: auto;
  word-break: break-all !important;
  word-wrap: break-word !important;
}

.tooltip-alignment {
  margin-left: 12px !important;
}

.editable_column {
  background-color: #d2f0fc;
}

.k-checkbox {
  border-radius: 2px;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  line-height: initial;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
}

.k-button {
  border-radius: 2px;
  padding: 4px 8px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  line-height: 1.4285714286;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 4px;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  position: relative;
}

.k-button-primary,
.k-button.k-primary {
  border-color: #ff6358;
  color: #ffffff;
  background-color: #ff6358;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.04));
}

.k-button-primary:hover,
.k-button.k-primary:hover,
.k-button-primary.k-state-hover,
.k-state-hover.k-button.k-primary {
  border-color: #f55f54;
  background-color: #f55f54;
}

.k-button-primary:focus,
.k-button.k-primary:focus,
.k-button-primary.k-state-focus,
.k-state-focus.k-button.k-primary,
.k-button-primary.k-state-focused,
.k-state-focused.k-button.k-primary {
  box-shadow: 0 0 0 2px rgba(255, 99, 88, 0.3);
}

.k-button-primary:active,
.k-button.k-primary:active,
.k-button-primary.k-state-active,
.k-state-active.k-button.k-primary {
  border-color: #e0574d;
  background-color: #e0574d;
}

.k-button-primary.k-state-selected,
.k-state-selected.k-button.k-primary {
  border-color: #e0574d;
  background-color: #e0574d;
}

.k-textbox {
  border-radius: 2px;
  margin: 0;
  padding: 0;
  width: 12.4em;
  height: calc(1.4285714286em + 10px);
  border-width: 1px;
  border-style: solid;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  box-sizing: border-box;
  box-shadow: none;
  background: none;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  position: relative;
  -webkit-appearance: none;
}

.k-textbox::-ms-clear {
  display: none;
}

.k-textbox:-ms-input-placeholder {
  color: #666666;
  opacity: 1;
  -ms-user-select: none;
  user-select: none;
}

.k-textbox::placeholder {
  color: #666666;
  opacity: 1;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.k-textbox:-ms-input-placeholder {
  color: #666666;
}

.k-textbox .k-button-clear {
  width: calc(1.4285714286em + 8px);
  height: calc(1.4285714286em + 8px);
  border: 0;
}

.k-textbox .k-textbox-separator {
  margin: 0;
  width: 0;
  height: 16px;
  border-width: 0 0 0 1px;
  border-style: solid;
}

.k-textbox:disabled,
.k-textbox[disabled],
.k-textbox.k-state-disabled {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

.k-textbox:disabled::selection,
.k-textbox[disabled]::selection,
.k-textbox.k-state-disabled::selection {
  color: #424242;
  background-color: transparent;
}

input.k-textbox {
  padding: 4px 8px;
}

.k-textbox {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}

.k-textbox::selection,
.k-textbox .k-input::selection {
  color: #ffffff;
  background-color: #ff6358;
}

.k-textbox .k-textbox-separator {
  border-color: #424242;
  opacity: 0.5;
}

.k-textbox:focus,
.k-textbox.k-state-focus,
.k-textbox.k-state-focused {
  border-color: rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}

.k-textbox:focus-within {
  border-color: rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}

.k-textbox:hover,
.k-textbox.k-state-hover {
  border-color: rgba(0, 0, 0, 0.16);
}

.k-textbox.k-invalid,
.k-textbox.k-state-invalid,
.k-textbox.ng-invalid.ng-touched,
.k-textbox.ng-invalid.ng-dirty {
  border-color: rgba(213, 25, 35, 0.5);
}
