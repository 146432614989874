$primary-color: #14487F;
$secondary-color: #43B049;
$danger-color:#EB3023;
$warning-color:#ff991f;
$footer-white: #F2F2F2;
$gray-color: #455560;
$light-gray-color: #718096;
$light-background: #F9F9F9;
$tile-color: #4D4D4D;
$disabled-gray: #7A7A7A;
$font-primary: "Open Sans", sans-serif;
$hightlight-color:#36B4EA;
$error-message: #F24F47;
$error-message-border: #C5362F;

$font-xs: 12px;
$font-s: 14px;
$font-m: 16px;
$font-l: 18px;
$font-xl: 24px;
$font-xl2: 30px;
$font-xxl: 36px;
$font-xs-em: 0.75em;
$font-s-em: 1em;
$font-m-em: 1.5em;